import { render, staticRenderFns } from "./five.vue?vue&type=template&id=f04fec30&scoped=true&"
import script from "./five.vue?vue&type=script&lang=js&"
export * from "./five.vue?vue&type=script&lang=js&"
import style0 from "./five.vue?vue&type=style&index=0&id=f04fec30&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f04fec30",
  null
  
)

export default component.exports